.success {
  padding-top: 70px;
  overflow: hidden;
}
.success__cover {
  width: 70%;
  margin: auto;
}
.success__blogs {
  width: 70%;
  margin: auto;
}
