.mainHeader {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;

  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 100%;
  /* animation */
  transition-timing-function: ease-in !important;
  transition: all 0.5s !important;
  box-shadow: none !important;
  position: fixed;
  z-index: 100;
}
.mainHeader__appear {
  background-color: white;
  /* animation */
  transition-timing-function: ease-in !important;
  transition: all 0.5s !important;
}
.mainHeader__darkLogo {
  display: none;
}
.mainHeader__darkLogoAppear {
  position: relative;
  display: inline;
}
.mainHeader__logoDisappear {
  display: none;
}

.mainHeader__links {
  display: flex;
  justify-content: space-evenly;
  width: 57%;
  flex-direction: row;
}
.mainHeader__link {
  text-decoration: none;
  display: flex;
}

.mainHeader__logo {
  font-size: 25px;
  font-weight: bold;
  padding-right: 20;
  margin-right: 10px;
  padding-left: 50px;
}

.mainHeader__button {
  align-self: center;
  box-shadow: none !important;
  color: white !important;
  background: transparent !important;
}
.mainHeader__buttonAppear {
  color: #07535c !important;
  font-weight: bolder;
}
.burger {
  color: darkcyan !important;
}
