.webinar {
  height: 100%;
  width: 100%;
  padding-top: 50px;
}
.webinar__cover {
  height: 80vh;
  width: 90%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 300px;
}
