.contactUs {
  padding-top: 50px;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
.contactUs__bg {
  background-color: #33747f;
  height: 350;
}
.contactUs__nav {
  width: 50%;
  margin: auto;
  padding-top: 50px;
  height: auto;
}
.contactUs__navHeader {
  padding-bottom: 50px;
  width: 100%;
  color: white;
  margin: auto;
}
.contactUs__navButton {
  display: flex;
  align-items: center !important   ;
  justify-content: center;
}
.contactUs__infos {
  padding-top: 25%;
  width: 70%;
  margin: auto;
  padding-bottom: 50px;
}

.contactUs__cards {
  width: 90%;
  margin: auto;
}
.card {
  width: 300px;
  height: 300px;
}
.text {
  width: fit-content;
}
