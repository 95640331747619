.logos {
  padding-top: 100px;
  padding-bottom: 100px;
}
.logos img {
  height: 30px;
  max-width: 100%;
  image-rendering: auto;
  object-fit: contain;
  padding: 20px;
}
