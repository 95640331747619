.description {
  padding-left: 15%;
  padding-right: 15%;
}
body1 {
  font-size: x-large;
  font-weight: lighter;
}
.description__table {
  padding-top: 50px;
}
