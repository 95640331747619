.footer {
  height: 100%;
  bottom: 0;
  padding-top: 100px;
}
.footer__logo {
  width: 100%;
  margin: auto;
}

.footer img {
  height: 30px;
  padding-top: 20px;
}
.footer__grid {
  padding-top: 50px;
  width: 85%;
  margin-left: 15%;
}

.footer__link {
  color: darkcyan;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}
.footer__infos {
  display: flex;
  justify-content: space-between;

  padding-top: 50px;
  width: 70%;
  margin: auto;
  color: darkcyan;
  font-size: large;
  font-weight: bold;
}
.footer__socials {
  display: flex;
  flex-wrap: nowrap;
}
