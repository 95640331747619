.security {
  height: 100%;
}
.security__cover {
  background-image: url("https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7e6846dad44d1a302260ed_security-header-bg.png");
  background-position: 0% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-top: 50px;
}
.security__coverText {
  color: white;
  padding-top: 70px;
  padding-left: 17%;
  width: 60%;
  font-weight: bolder !important;
}
.security__description {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 50%;
  margin: auto;
}
.security__section1 {
  width: 50%;
  margin: auto;
}
.security__section2 {
  width: 50%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.security__section3 {
  width: 50%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.security__section3 img {
  height: 150px;
  width: 150px;
  object-fit: contain;
}
.security__link {
  border-radius: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 60vh;
  background-color: darkcyan;
  height: 30vh;
  margin: auto;
  color: white;
  margin-bottom: 50px;
}
