.faqs {
  height: 100%;
  width: 100%;
  padding-top: 80px;
}
.faqs__accordion {
  width: 90%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
