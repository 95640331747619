.about {
  background-image: url("//assets.website-files.com/5e78c024a853e17dfffdb941/5e8618c6a8dec455fdd5d7de_header-graphic-about.svg");
  height: 100%;
  width: 100%;
  padding-bottom: 50px;
}

.about__description {
  padding-top: 100px;
  width: 40%;
  margin: auto;
  color: #000;
}
.about__descriptionSubtitle {
  padding-top: 100px;
}

.about__team {
  padding-top: 50vh;
}

.about__teamFounder {
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
  height: auto;
}
.about__avatar {
  margin: auto !important;
  height: 130px !important;
  width: 130px !important;
  object-fit: contain !important;
}
.about__teamBoard {
  padding-top: 50px;
  height: auto;
}
.about__teamBoardGrid {
  padding-top: 50px !important;
}
