.comingSoon {
  background-color: #4cb3df;
  height: 100%;
  position: fixed;
  width: 100%;
}
img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  top: 20px;
  right: 0;
}
.comingSoon__cover {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 5px;
  right: 0;
  height: 25%;
}
