.testimonial {
  margin-top: 50px;

  display: grid;
  place-items: center;
  width: 100%;
  background-color: black;
  height: auto;
  margin-bottom: 20px;
}
