.diet {
  width: 100%;
}
.diet__cover {
  padding-top: 100px;
  height: 80vh;
  background-image: url("https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7d19a103f179436e7709ca_header-bg-deflections.png");
  background-position: 50% 50%;
  background-size: cover;
}
.diet__coverDescription {
  color: white;
  width: 80%;
  margin: auto;
}
.diet__section1 {
  padding-top: 70px;
  width: 75%;
  margin: auto;
}
.diet__section2 {
  width: 75%;
  margin: auto;
}
.diet__section3 {
  width: 60%;
  margin: auto;
  padding-bottom: 80px;
}
.diet__section4 {
  margin-top: 50px;
  width: 60%;
  border-radius: 25px;
  background-color: tomato;
  height: auto;
  margin: auto;
}
