.cover {
  margin-top: 0px;
  padding-top: 10%;
  padding-bottom: 100px;
  background-image: url("https://assets.website-files.com/5e78c024a853e17dfffdb941/5e78c3e222c80f11e3bd4e74_header-bg.png");
  background-position: 70% 50%;
  background-size: cover;
  height: 100%;
  width: 100%;
}
.cover__text {
  color: white;
  margin-left: 15%;
  margin-right: 15%;
  font-size: 18px;
  margin-bottom: 25px;
}
.cover__text > h1 {
  display: block;
}

.cover__text > p {
  display: block;
}
