.customerGrid {
  display: grid;
  place-items: center;
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 30px;
}
.customerGrid__infos {
  padding-top: 30px;
  width: 100%;
}
.MuiTypography-root {
  padding-top: 20px;
  padding-bottom: 20px;
}
