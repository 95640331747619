.workouts {
  height: 100%;
  overflow: hidden;
}
.workouts__cover {
  padding-top: 100px;
  height: 80vh;
  background-image: url("https://assets.website-files.com/5e78c024a853e17dfffdb941/5e7d2f9f0f50f75c4c7f96e8_header-bg-predictions.png");
  background-position: 50% 50%;
  background-size: cover;
}
.workouts__coverDescription {
  color: white;
  width: 75%;
  margin: auto;
  height: auto;
}
.workouts__section1 {
  padding-top: 70px;
  width: 75%;
  margin: auto;
}
.workouts__section2 {
  width: 75%;
  margin: auto;
  padding-top: 150px;
}
.workouts__section3 {
  padding-top: 50px;
  width: 60%;
  margin: auto;
  padding-bottom: 80px;
}
.workouts__section4 {
  margin-top: 50px;
  width: 60%;
  border-radius: 25px;
  background-color: red;
  height: auto;
  margin: auto;
}
