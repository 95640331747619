.header {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: white;
  display: flex;
  justify-content: space-between;

  height: 30px;
  width: 100%;
  /* animation */
  transition-timing-function: ease-in !important;
  transition: all 0.5s !important;
  box-shadow: none !important;
  position: fixed;
  z-index: 100;
}

.header__links {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}
.header__link {
  text-decoration: none;
  display: flex;
}

.header__logo {
  font-size: 25px;
  font-weight: bold;

  padding-left: 10%;
}

.header__button {
  align-self: center;
  box-shadow: none !important;
  color: #07535c !important;
  background: transparent !important;
  font-weight: bolder;
  font-size: 12px !important;
}
.header__buttonAppear {
  color: black !important;
  font-weight: bolder;
}
.burger {
  color: darkcyan !important;
  padding-right: 100px !important;
}
