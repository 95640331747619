.press {
  height: 100%;
  width: 100%;
  padding-top: 70px;
  padding-bottom: 70px;
  overflow-x: hidden;
}

.press__cover {
  height: 40vh;
  width: 85%;
  margin: auto;
  padding-top: 15%;
}
.press__cards {
  padding-top: 60vh !important;
  width: 70%;

  margin: auto;
}
