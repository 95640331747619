.careers {
  padding-top: 50px;

  width: 100%;
}
.careers__cover {
  background-image: url("https://assets.website-files.com/5e78c024a853e17dfffdb941/5e8618c6a8dec455fdd5d7de_header-graphic-about.svg");
  height: 2%;
  padding-top: 50px;
  width: 70%;
  margin: auto;
}
.careers__cover img {
  height: 500px;
  width: 100%;
  object-fit: contain;
}
.careers__coverButton {
  display: flex;
  justify-content: center;
}

.careers__description {
  width: 80%;
  margin: auto;
  padding-bottom: 50px;
}
.careers__grid {
  width: 80%;
  margin: auto;
  padding-top: 100px;
}
